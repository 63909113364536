import React from "react";
import "./headContent.css";
import botImage from "../../assets/chatbot.png";
import girlLookingPhone from "../../assets/girl_looking_phone.jpg";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button } from "@mui/material";
import { HeaderText, Subtitle } from "../../components/texts";

const HeadContent: React.FC = () => {
  return (
    <section className="head" id="head">
      <div className="headWrapper">
        <div className="content">
          <Subtitle>
            Agentes conversacionais Mazoto
          </Subtitle>
          <HeaderText>Atendimento 24 horas para seus clientes no WhatsApp</HeaderText>

          <Button variant="contained" sx={{marginTop: "2em", padding: "1em", fontSize: "1.2rem", fontWeight: "bold", textTransform: "none"}} startIcon={<WhatsAppIcon/>} href="https://api.whatsapp.com/send?phone=5511912604740&text=Olá%2C%20estou%20interessado%20em%20saber%20mais%20sobre%20o%20Assistente%20Mazoto" target="_blank">Solicitar orçamento</Button>
        </div>
        <div className="image-container">
          <img src={girlLookingPhone} alt="Assistente Mazoto" className="img" />
        </div>
      </div>
    </section>
  );
}

export default HeadContent;
