import { Typography } from "@mui/material";
import { FC, CSSProperties } from "react";

interface TextProps {
  style?: CSSProperties;
  children: React.ReactNode;
}

const HeaderText: FC<TextProps> = (props) => (
  <Typography
    variant="h1"
    sx={{
      fontSize: "4rem",
      fontWeight: "bold",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const Title: FC<TextProps> = (props) => (
  <Typography
    variant="h2"
    sx={{
      fontSize: "3rem",
      fontWeight: "bold",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const SmallTitle: FC<TextProps> = (props) => (
  <Typography
    variant="h2"
    sx={{
      fontWeight: "bold",
      fontSize: "1.2rem",
      color: "#333",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const Subtitle: FC<TextProps> = (props) => (
  <Typography
    variant="subtitle1"
    sx={{
      fontSize: "1.5rem",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const Content: FC<TextProps> = (props) => (
  <Typography
    variant="body1"
    sx={{
      fontSize: "1rem",
      width: "100%",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const SmallText: FC<TextProps> = (props) => (
  <Typography
    variant="body2"
    sx={{
      fontSize: "0.75rem",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

const ThinText: FC<TextProps> = (props) => (
  <Typography
    variant="h2"
    sx={{
      fontSize: "32pt",
      fontWeight: "100",
    }}
    style={props.style}
  >
    {props.children}
  </Typography>
);

export {
  Title,
  SmallTitle,
  Content,
  Subtitle,
  SmallText,
  HeaderText,
  ThinText,
};
