import React from "react";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";

export const CustomTheme = (primaryColor: string) =>
    createTheme({
        palette: {
            primary: {
                main: primaryColor,
            },
        },
    });