import React from "react";
import Grid from '@mui/material/Grid2';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SmallText } from "./texts";
import { Stack, Box } from "@mui/material";

function Footer() {
    return (
        <Grid container spacing={2} sx={{ maxHeight: "200px", width: "100%", backgroundColor: "#333", padding: '1rem'}} columns={{ xs: 2, sm: 2, md: 12 }}>
            <Grid size={12}>
                <Stack
                    direction={"row"}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: 'wrap'
                    }}
                    spacing={2}>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <EmailIcon color="primary" />
                        <SmallText style={{ color: "#fff" }}>
                            mazoto.company@gmail.com
                        </SmallText>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <PhoneIcon color="primary" />
                        <SmallText style={{ color: "#fff" }}>
                            (11) 91260-4740
                        </SmallText>
                    </Box>
                    <a href="https://www.facebook.com/profile.php?id=61566607533497" target="_blank" rel="noopener noreferrer"><FacebookIcon color="secondary" /></a>
                    <a href="https://www.linkedin.com/in/mazoto-company-a76667339/" target="_blank" rel="noopener noreferrer"><LinkedInIcon color="secondary" /></a>
                    <a href="https://www.instagram.com/mazoto.company/" target="_blank" rel="noopener noreferrer"><InstagramIcon color="secondary" /></a>
                </Stack>
            </Grid>
        </Grid>
    );
}
export default Footer;