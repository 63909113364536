import { fetchAuthSession } from "@aws-amplify/auth";
import TriageTableData from "../interfaces/triageTableData";

const API_URL = "https://zkl4h2ptabz6jjlqowblqa6jiq0jkdvx.lambda-url.sa-east-1.on.aws/triage";

export async function fetchTriages(establishmentId: string) {
  try {
    const session = await fetchAuthSession();
    const token = session.tokens?.idToken?.toString();

    if (!token) throw new Error("Usuário não autenticado!");

    
    const response = await fetch(`${API_URL}?establishment_id=${establishmentId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` 
      }
    });
    
    if (!response.ok) throw new Error("Erro ao buscar triagens");
    const data = await response.json();
    
    console.log(data);
    return data;
  } catch (error) {
    console.error("Erro ao buscar triagens:", error);
    return null;
  }
}


export async function postTriages(triageData: TriageTableData[]) {
  try {
      const session = await fetchAuthSession();
      const token = session.tokens?.idToken?.toString();

      if (!token) throw new Error("Usuário não autenticado!");

      const response = await fetch(API_URL, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(triageData)
      });

      if (!response.ok) throw new Error("Erro ao enviar triagens");
      
      return await response.json();
  } catch (error) {
      console.error("Erro ao enviar triagens:", error);
      return null;
  }
}
