import Grid from '@mui/material/Grid2';
import { ThinText } from '../../components/texts';
import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Calendar } from '../../components/calendar';
import { SelectorList } from '../../components/selectorList';
import { ThemeProvider } from '@mui/material/styles';
import { CustomTheme } from '../../components/customTheme';
import React from 'react';


const BookForm: React.FC = () => {
    const [service, setService] = React.useState('');
    const [hourList, setHourList] = React.useState<{id: string, name: string}[]>([]);
    const [selectedHour, setSelectedHour] = React.useState<string | null>(null);


    const handleService = (event: SelectChangeEvent<string>) => {
        setService(event.target.value as string);
    };

    const handleHour = (id:string) => {
        setSelectedHour(id);
    };

    const getHourList = () => {
        const hours = [];
        for (let i = 8; i < 18; i++) {
            hours.push({id: i.toString(), name: i.toString() + ":00"});
        }
        setHourList(hours);
    };

    const theme = CustomTheme("#313131");
    return (
        <ThemeProvider theme={theme}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundColor: "#C3C3C3" }}>
                <Grid container spacing={4} width="var(--page-center-size)" columns={{ sm: 6, md: 12 }} >
                    <Grid size={12}>
                        <ThinText style={{ fontStyle: "italic", color: "#313131", textAlign: "center" }}>
                            Seja bem-vindo! Agende seu horário
                        </ThinText>
                    </Grid>
                    <Grid size={12}>
                        <InputLabel sx={{ fontWeight: "bold" }}>
                            Escolha o serviço
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={service}
                            onChange={handleService}
                            fullWidth>
                            <MenuItem value={10}>Corte</MenuItem>
                            <MenuItem value={20}>Barba</MenuItem>
                        </Select>
                    </Grid>
                    <Grid size={6}>
                        <InputLabel sx={{ fontWeight: "bold" }}>
                            Nome
                        </InputLabel>
                        <TextField
                            required
                            id="name"
                            variant="outlined"
                            fullWidth>
                        </TextField>
                    </Grid>
                    <Grid size={6}>
                        <InputLabel sx={{ fontWeight: "bold" }}>
                            Whatsapp
                        </InputLabel>
                        <TextField
                            required
                            id="phoneNumber"
                            variant="outlined"
                            fullWidth>
                        </TextField>
                    </Grid>
                    <Grid size={6}>
                        <InputLabel sx={{ fontWeight: "bold" }}>
                            Qual o melhor dia pra você?
                        </InputLabel>
                        <Calendar />
                    </Grid>
                    <Grid size={6}>
                        <InputLabel sx={{ fontWeight: "bold" }}>
                            Escolha o melhor horário
                        </InputLabel>
                        <SelectorList 
                            items={hourList}
                            selectedItem={selectedHour}
                            onItemClick={handleHour}/>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}
export default BookForm;