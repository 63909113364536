import "./navbar.css";
import logo from "../assets/logo_mazoto_simples_transparent.png";
import React from "react";
import { Button } from "@mui/material";

const Navbar: React.FC = () => {
  return (
    <header className="header">
      <div className="logoWrapper">
        <a href="#head"><img src={logo} className="logo" alt="logo" /></a>
      </div>
      <div className="headerWrapper">
        <nav className="nav">
          <a
            href="#about"
            className="link"
            rel="noopener noreferrer"
          >
            Serviços
          </a>
          <a
            href="#who-we-are"
            className="link"
            rel="noopener noreferrer"
          >
            Quem Somos
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=5511912604740&text=Olá%2C%20estou%20interessado%20em%20saber%20mais%20sobre%20o%20Assistente%20Mazoto"
            className="link"
            rel="noopener noreferrer"
            target="_blank"
            style={{ backgroundColor: '#2AA24C', color: '#fff', padding: '10px 10px', borderRadius: '4px', border: '2px solid #fff' }}
          >
            Contate-nos
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
