import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Button, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TriageTableData from '../interfaces/triageTableData';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import dayjs from 'dayjs';

const TriageDataRow: React.FC<{ row: TriageTableData }> = ({ row }) => {
    const [open, setOpen] = React.useState(false);
    const date = dayjs(row.date).format('DD/MM/YYYY')
    return (
        <>
            <TableRow 
                sx={{ '& > *': { borderBottom: 'unset' },  cursor: 'pointer', '&:hover': {backgroundColor: '#eee'}}}
                onClick={() => setOpen(!open)}
            >
                <TableCell component="th" scope="row">{row.customer_name}</TableCell>
                <TableCell>{row.gender}</TableCell>
                <TableCell>{date}</TableCell>
                <TableCell>
                    <Tooltip title="Enviar mensagem">
                        <IconButton href={row.whatsapp} target='_blank' color='success'>
                            <WhatsAppIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Tooltip title="Expandir detalhes">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow >
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Detalhes
                            </Typography>
                            <Table size="small" aria-label="details">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Peso</TableCell>
                                        <TableCell>{row.weight}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Altura</TableCell>
                                        <TableCell>{row.height}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Idade</TableCell>
                                        <TableCell>{row.age}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Alergias</TableCell>
                                        <TableCell>{row.alergies}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Objetivo</TableCell>
                                        <TableCell>{row.objective}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default TriageDataRow;