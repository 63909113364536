import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Landing } from "./pages/landing/landing";
import { BrowserRouter, Route, Routes } from "react-router";
import BookForm from './pages/book/bookForm';
import Triage from './pages/triage/triage';
import Terms from './pages/privacy/terms';
import Privacy from './pages/privacy/privacy';
import Login from './pages/login/login';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2AA24C"
    },
    secondary: {
      main: "#2874A4"
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Landing/>}/>
          <Route path="book" element={<BookForm/>}/>
          <Route path="triage/studiumk" element={<Triage/>}/>
          <Route path="terms" element={<Terms/>}/>
          <Route path="privacy" element={<Privacy/>}/>
          <Route path="login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
