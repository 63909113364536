import WhoWeAre from "./whoWeAre";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import HeadContent from "./headContent";
import AboutAssistantMazoto from "./aboutAssistantMazoto";
import "./landing.css";

export const Landing: React.FC = () => {
    return (
        <div className="main">
            <Navbar/>
            <HeadContent />
            <AboutAssistantMazoto />
            <WhoWeAre/>
            <Footer/>
        </div>
    )
}
