import React from "react";
import Team from "../../assets/team.png";
import Grid from '@mui/material/Grid2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Stack, Button, Box } from "@mui/material";
import { Content,  Title } from "../../components/texts";

const WhoWeAre: React.FC = () => {
    return (
        <Grid id="who-we-are" container spacing={2} sx={{ padding: "100px 30px 100px 30px", width: "var(--page-size)" }} columns={{ xs: 5, sm: 5, md: 12 }} alignItems="stretch">
            <Grid size={5}>
                <img src={Team} alt="Mazoto team" style={{ maxWidth: "600px", width: "100%" }} />
            </Grid>
            <Grid size={7}>
                <Stack spacing={1} justifyContent="space-evenly" height={"100%"}>
                    <Title>
                        Quem Somos
                    </Title>
                    <Stack spacing={2}>
                        <Content>
                            Somos a Mazoto, uma empresa que ama tecnologia e que acredita que com ela conseguimos tornar o relacionamento entre o cliente e você muito melhor, pois, com nossos serviços, você é capaz de estar 100% presente e despreocupado porque nós estaremos cuidando de tudo.
                        </Content>
                        <Content>
                            E aí, bora descomplicar seus processos de atendimento e embarcar nessa juntos?
                        </Content>
                    </Stack>
                    <Box>
                        <Button variant="contained" color="secondary" sx={{ fontSize: "1.2rem", textTransform: "none", fontWeight: "bold" }} startIcon={<WhatsAppIcon/>} href="https://api.whatsapp.com/send?phone=5511912604740&text=Olá%2C%20estou%20interessado%20em%20saber%20mais%20sobre%20o%20Assistente%20Mazoto" target="_blank">Clique aqui e converse conosco</Button>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default WhoWeAre;
