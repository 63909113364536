import TriageTableData from "../interfaces/triageTableData";

const sampleTriageData: TriageTableData[] = [
    {
        triage_id: "1",
        customer_name: "João Silva",
        gender: "Masculino",
        weight: "70 a 75 kg",
        height: "170 a 175 cm",
        age: "30 a 35 anos",
        objective: "Check-up Geral",
        date: "2023-10-01",
        whatsapp: "https://wa.me/1234567890",
        alergies: "Sim"
    },
    {
        triage_id: "2",
        customer_name: "Ana Souza",
        gender: "Feminino",
        weight: "55 a 60 kg",
        height: "160 a 165 cm",
        age: "25 a 30 anos",
        objective: "Consulta",
        date: "2023-10-02",
        whatsapp: "https://wa.me/0987654321",
        alergies: "Sim"
    },
    {
        triage_id: "3",
        customer_name: "Alice Santos",
        gender: "Feminino",
        weight: "50 a 55 kg",
        height: "155 a 160 cm",
        age: "25 a 30 anos",
        objective: "Acompanhamento",
        date: "2023-10-03",
        whatsapp: "https://wa.me/1112223333",
        alergies: "Não"
    },
    {
        triage_id: "4",
        customer_name: "Roberto Lima",
        gender: "Masculino",
        weight: "75 a 80 kg",
        height: "175 a 180 cm",
        age: "35 a 40 anos",
        objective: "Rotina",
        date: "2023-10-04",
        whatsapp: "https://wa.me/4445556666",
        alergies: "Sim"
    },
    {
        triage_id: "5",
        customer_name: "Carlos Pereira",
        gender: "Masculino",
        weight: "85 a 90 kg",
        height: "180 a 185 cm",
        age: "40 a 45 anos",
        objective: "Consulta",
        date: "2023-10-05",
        whatsapp: "https://wa.me/7778889999",
        alergies: "Sim"
    },
    {
        triage_id: "6",
        customer_name: "Diana Oliveira",
        gender: "Feminino",
        weight: "60 a 65 kg",
        height: "165 a 170 cm",
        age: "30 a 35 anos",
        objective: "Check-up Geral",
        date: "2023-10-06",
        whatsapp: "https://wa.me/0001112222",
        alergies: "Não"
    },
    {
        triage_id: "7",
        customer_name: "Ethan Costa",
        gender: "Masculino",
        weight: "70 a 75 kg",
        height: "175 a 180 cm",
        age: "25 a 30 anos",
        objective: "Acompanhamento",
        date: "2023-10-07",
        whatsapp: "https://wa.me/3334445555",
        alergies: "Não"
    },
    {
        triage_id: "8",
        customer_name: "Fiona Verde",
        gender: "Feminino",
        weight: "55 a 60 kg",
        height: "160 a 165 cm",
        age: "25 a 30 anos",
        objective: "Rotina",
        date: "2023-10-08",
        whatsapp: "https://wa.me/6667778888",
        alergies: "Não"
    },
    {
        triage_id: "9",
        customer_name: "Jorge Ferreira",
        gender: "Masculino",
        weight: "80 a 85 kg",
        height: "180 a 185 cm",
        age: "35 a 40 anos",
        objective: "Consulta",
        date: "2023-10-09",
        whatsapp: "https://wa.me/9990001111",
        alergies: "Não"
    },
    {
        triage_id: "10",
        customer_name: "Hanna Branco",
        gender: "Feminino",
        weight: "60 a 65 kg",
        height: "165 a 170 cm",
        age: "30 a 35 anos",
        objective: "Check-up Geral",
        date: "2023-10-10",
        whatsapp: "https://wa.me/2223334444",
        alergies: "Não"
    }
];

export default sampleTriageData;
