import * as React from 'react';
import { ListItemButton, ListItemText, List } from '@mui/material';
import SelectorListProps from '../interfaces/selectorListProps';

export const SelectorList: React.FC<SelectorListProps> = ({items, selectedItem, onItemClick}) => {
    return (
        <List sx={{ backgroundColor: '#fff', borderRadius: '5px' }}>
            {items.map((item) => (
            <ListItemButton
                key={item.id}
                selected={selectedItem === item.id}
                onClick={(event) => onItemClick(item.id)}
            >
                <ListItemText
                    primary={item.name}
                    sx={{ textAlign: 'center' }}
                />
            </ListItemButton>
            ))};
        </List>
    );
}
