import Grid from '@mui/material/Grid2';
import TriageTableData from "../../interfaces/triageTableData";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TriageDataRow from "../../components/triageDataRow";
import { SmallTitle, ThinText, Title } from '../../components/texts';
import Header from '../../components/header';
import StudiumKProfile from '../../assets/profiles/studiumK.jpeg';
import Background from '../../assets/headers/bg.png';
import { Button, Tab } from '@mui/material';
import { fetchTriages, postTriages } from '../../services/apiService';
import sampleTriageData from '../../data/sampleTriageData';
import { useState } from 'react';

const Triage: React.FC = () => {
    const [rows, setRows] = useState<TriageTableData[]>([]);

    React.useEffect(() => {
        async function loadData() {
            const data = await fetchTriages("NUTRIA"); 
            if (data && Array.isArray(data.data)) {
                setRows(data.data); 
            } else {
                console.error("Erro: API não retornou um array!", data);
                setRows([]); 
            }
        }
        loadData();
    }, []);

    const handleSendData = async () => {
        try {
            const response = await postTriages(sampleTriageData);
            console.log("Triagens enviadas com sucesso:", response);
            alert("Dados enviados com sucesso!");
        } catch (error) {
            console.error("Erro ao enviar triagens:", error);
            alert("Erro ao enviar dados.");
        }
    };

    console.log(rows);
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div style={{ width: '100vw', marginBottom: '10vh' }}>
                <Header profilePath={StudiumKProfile} headerPath={Background} companyName='Studium K' />
            </div>
            <Grid container spacing={4} width="var(--page-center-size)" columns={{ sm: 6, md: 12 }} >
                <Grid size={12}>
                    <SmallTitle style={{ color: "#313131" }}>
                        Triagem de pacientes
                    </SmallTitle>
                </Grid>
                <Grid size={12}>
                    {/* <Button variant="contained" color="primary" onClick={handleSendData} sx={{ mb: 2 }}>
                        Enviar Dados
                    </Button> */}
                </Grid>
                <Grid size={12}>
                    <TableContainer component={Paper} sx={{ maxHeight: '500px', overflow: 'auto' }}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Gênero</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>WhatsApp</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TriageDataRow key={row.triage_id} row={row} /> // Usando o componente Row para cada item da API
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default Triage;