import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import { signIn, signOut } from "@aws-amplify/auth";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import "./login.css";

// Configuração do Cognito com telefone
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '77fs18eruf5qvgirie23tv5sl',
      userPoolId: 'sa-east-1_YrVHd2mtO',
      loginWith: {
        oauth: {
          domain: 'https://cognito-idp.sa-east-1.amazonaws.com/sa-east-1_YrVHd2mtO/.well-known/jwks.json',
          scopes: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: ['http://localhost:3000/', 'https://mazoto.com.br/'],
          redirectSignOut: ['http://localhost:3000/', 'https://mazoto.com.br/'],
          responseType: 'code',
        },
        username: true,
        email: false,
        phone: true,
      }
    }
  }
});

const Login: React.FC = () => {
  const [phone, setPhone] = useState("+55"); 
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      await signIn({ username: phone, password }); 
      alert("Login bem-sucedido!");
      window.location.href = "/triage/studiumk";
    } catch (err: any) {
      setError(err.message);
    }
  };


  const handleLogout = async () => {
    try {
      await signOut();
      window.location.reload();
    } catch (error) {
      console.error("Erro ao deslogar:", error);
    }
  };







  return (
    <Container className="loginContainer" maxWidth="xs">
      <Box sx={{ mt: 8, p: 4, boxShadow: 3, borderRadius: 2, textAlign: "center" }}>
        <Typography variant="h5" gutterBottom>
          Login com Telefone
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <TextField
          label="Número de Telefone"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="Senha"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box className="buttonWrapper">
        <Button variant="contained" color="primary" fullWidth onClick={handleLogin} sx={{ mt: 2 }}>
          Entrar
        </Button>
        <Button onClick={handleLogout} color="secondary" style={{ padding: "10px", fontSize: "16px", cursor: "pointer" }}>
          Sair
        </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
