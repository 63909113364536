import "./aboutAssistantMazoto.css";
import React from "react";
import CardAbout from "../../components/card";
import { Content, Title } from "../../components/texts";
import Grid from '@mui/material/Grid2';
import { Stack, Divider } from "@mui/material";

function AboutAssistantMazoto() {
  return (
    <section className="aboutAssistantMain" id="about">
      <Stack
        spacing={2}
        divider={<Divider orientation="horizontal" flexItem />}
        sx={{ textAlign: "left", marginTop: "2em" }}
      >
        <Title>Serviços Mazoto</Title>
        <Content style={{ maxWidth: "800px", padding: "0px 15px 0px 0px" }}>
          Contamos com soluções prontas para seu negócio e também desenvolvemos soluções 
          que atenderão suas necessidades de atendimento        
        </Content>
      </Stack>
      <Grid container spacing={2} columns={{ xs: 4, sm: 4, md: 12 }} sx={{ width: "clamp(300px, 90%, 1200px)", marginBottom: "2em" }}>
        <Grid size={4}>
          <CardAbout title="Assistente de agendamento">
            Consulta sua agenda e verifica se estará disponível para o horário que o cliente gostaria de marcar
          </CardAbout>
        </Grid>
        <Grid size={4}>
          <CardAbout title="Triagem de clientes">
            Recebe o cliente no whatsapp e coleta as informações que seu negócio precisa para atendê-lo
          </CardAbout>
        </Grid>
        <Grid size={4}>
          <CardAbout title="Assistente personalizado">
            Criamos um assistente que se adequa a seus processos, e automatiza procedimentos
          </CardAbout>
        </Grid>
      </Grid>
    </section>
  );
}
export default AboutAssistantMazoto;
