import React from "react";
import { ThinText, Title } from "./texts";

const Header = (props: { profilePath: string, headerPath: string, companyName: string }) => {
    return (
        <header style={{ width: '100vw', marginBottom: '2vh' }}>
            {/* <img src={props.headerPath} alt="Capa" style={{height: '10vh', width:'100vh'}}/> */}
            <div style={{ backgroundImage: `url(${props.headerPath})`, width: '100%', height: '15vh', display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginTop: '10vh', display: 'flex', alignItems: 'center', gap: '1em' }}>
                    <img src={props.profilePath} alt="Logo" style={{ height: '12vh', borderRadius: '50%', aspectRatio: 1, border: 'solid 4px #FFF' }} />
                    <ThinText style={{color: '#313131'}}>{props.companyName}</ThinText>
                </div>
            </div>
        </header>
    );
};
export default Header;