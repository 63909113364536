import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

export const Calendar: React.FC = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar sx={{ backgroundColor: '#fff', borderRadius: '5px', width:'100%'}} />
        </LocalizationProvider>
    );
}